@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body,html{
    font-family: 'Manrope', sans-serif;
    font-size: 10px;
    -webkit-font-smoothing : antialiased;
    background: #EDEDED;
    background-image: url(../assets/images/bg.png);
    background-position: top;
    background-size: auto;
    background-repeat: no-repeat;
    @media (max-width:768px){
        background-image: url(../assets/images/mobileBg.png);
        background-size: 100% 40%;
    }
}

.container{
    margin: 0 auto;
    padding: 0 24px;
    @media (min-width:1158px) {
        max-width: 1110px;
        padding: 0;
    }
}

.commonBtn{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding : 15px 24px;
    border-radius: 30px;
    color: #000000;
    outline: 1px solid #000000;
    background: #FFFFFF;
    text-decoration : none;
    display : flex;
    align-items : center;
    gap : 6px;
    width : fit-content;
}

img{
    max-width: 100%;
}

.gradientLine {
    height: 5px;
    width: 100%;
    background: linear-gradient(90deg, #E16A40 1.18%, #E6FF3C 24.64%, #4274FB 52.18%, #F6DFB3 77.17%, #E1BBFD 99.1%);
}